import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import PostLink from "../components/post-link"
import { Section, Container } from "../components/global"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <SEO
        title=" Blog [Ouro Estamparia Digital]"
        description="Fique por dentro do mundo da estamparia."
        pathname="/blog"
      />
      <Navigation />
      <Section id="top">
        <StyledContainer>
          <h1>Últimos artigos</h1>
          <br />
          {Posts}
        </StyledContainer>
      </Section>
      <Footer />
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YY")
            path
            title
            description
            thumbnail {
              childImageSharp {
                resize(width: 300) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`

const StyledContainer = styled(Container)`
  width: 60%;
  max-width: 670px;
  margin: 0px auto;
  padding-top: 10vh;

  text-align: justify;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    padding-top: 5vh;
  }

  p {
    line-height: 24px;
  }

  a {
    color: ${props => props.theme.color.accent};
  }

  ul {
    list-style: circle;
  }
`
