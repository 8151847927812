import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const PostLink = ({ post }) => (
	<StyledDiv>
		<PostItemDivLink to={post.frontmatter.path}>
			<PostThumbnailDiv>
				<img
					src={post.frontmatter.thumbnail.childImageSharp.resize.src}
					alt={post.frontmatter.title}
				/>
			</PostThumbnailDiv>
			<PostDescDiv>
				<PostDescDivTitle>{post.frontmatter.title}</PostDescDivTitle>
				<div>
					<PostDateDiv>publicado em {post.frontmatter.date}</PostDateDiv>
					<div>{post.frontmatter.description}</div>
				</div>
			</PostDescDiv>
		</PostItemDivLink>
		<br />
		<hr />
		<br />
		<br />
	</StyledDiv>
)

export default PostLink

const StyledDiv = styled.div`
	width: 100%;

	hr {
		border-style: ridge;
	}
`

const PostDescDivTitle = styled.div`
	${props => props.theme.font_size.large};
	font-family: ${props => props.theme.font.bold};

	@media (max-width: ${props => props.theme.screen.md}) {
		text-align: left;
	}
`

const PostItemDivLink = styled(Link)`
	display: grid;
	grid-template-columns: 2fr 5fr;
	@media (max-width: ${props => props.theme.screen.sm}) {
		grid-template-columns: 1fr;
	}

	text-decoration: none;
	color: ${props => props.theme.color.primary} !important;

	&hover {
		color: ${props => props.theme.color.primary} !important;
	}
	&:hover {
		${PostDescDivTitle} {
			color: ${props => props.theme.color.accent} !important;
		}
	}
`

const PostThumbnailDiv = styled.div`
	width: 100%;
	height: 100%;
	margin: auto;
	text-align: center;

	padding-top: 10px;

	@media (max-width: ${props => props.theme.screen.sm}) {
		display: none;
		padding-top: 0px;
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}
`

const PostDateDiv = styled.div`
	${props => props.theme.font_size.xxxsmall};
	color: ${props => props.theme.color.white.darker};
	margin-bottom: 4px;
`

const PostDescDiv = styled.div`
	min-height: 160px;
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-row-gap: 12px;

	padding-left:12px;

	@media (max-width: ${props => props.theme.screen.sm}) {
		padding-left:0px;
	}
`
